
<template>
    <div class="LuisaTryOut LuisaApp LuisaCommon">
      Comming soon 

    </div>
</template>
<style scoped>

</style>
<style lang="scss">

</style>
<script>



export default {
    name: 'LuisaTryOut',
    mixins:[],
    props: [],
    data: function () {
        return {  
        }
    },
    components: {
    },
    provide () {
        return {      
        }
    },
    computed: {   
       
    },
    methods: {
        
    },
    watch: {
       
    },
    async mounted () { 
        
        
    }
}
</script>