class HelpService  {


    getAll () {
        return new Promise( (resolve) => {
            if (!this.texts) {
                Promise.all([
                    import(/* webpackChunkName: "help" */ '../views/help/en/default.js'),
                    
                    import(/* webpackChunkName: "help" */ '../views/help/en/principle.js'),
                    //import(/* webpackChunkName: "help" */ '../views/help/en/figma.js'),
                    import(/* webpackChunkName: "help" */ '../views/help/en/plugin.js'),
                    import(/* webpackChunkName: "help" */ '../views/help/en/responsive.js'),
                    import(/* webpackChunkName: "help" */ '../views/help/en/data.js'),
                    import(/* webpackChunkName: "help" */ '../views/help/en/method.js'),
                    import(/* webpackChunkName: "help" */ '../views/help/en/custom.js'),
                    import(/* webpackChunkName: "help" */ '../views/help/en/existing.js'),
                    import(/* webpackChunkName: "help" */ '../views/help/en/deploy.js'),
                    import(/* webpackChunkName: "help" */ '../views/help/en/quantux.js'),             
                    // import(/* webpackChunkName: "help" */ '../views/help/en/handoff.js'),
                    //import(/* webpackChunkName: "help" */ '../help/en/default.js'),
                    //import(/* webpackChunkName: "help" */ '../help/en/default.js'),
                ]).then(all => {
                    this.texts = all.flatMap(t => t.texts)
                    this.texts.forEach(t => {
                        t._all = t.name.toLowerCase()
                        if (t.elements) {
                            t._all += " " + t.elements.map(p => p.content).join(' ')
                        }
                    })
                    resolve(this.texts)
                })
            } else {
                resolve(this.texts)
            }
        })
    }
}



export default new HelpService()