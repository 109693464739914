
<template>
      <p v-html="body" class="LuisaParagraph"></p>
</template>
<style scoped>

</style>
<style lang="scss">

</style>
<script>

export default {
    name: 'LuisaParagraph',
    mixins:[],
    props: ['element'],
    data: function () {
        return {        
           
        }
    },
    components: {},
    computed: {
        body () {
            if (this.element) {
                return this.element.content
            }
            return ''
        }
    },
    methods: {
    },
    watch: {
    },
    async mounted () {

    }
}
</script>