
<template>
      <div :class="[
            'LuisaImage', 
            {'LuisaImageXL': element.size === 'xl'}, 
            {'LuisaImageXS': element.size === 'xs'}, 
            {'LuisaImageScreen': element.size === 'screen'}, 
            {'LuisaImageFullWidth': element.size === 'fw'}
        ]">
          <div class="LuisaImageWrapper">
            <img :src="'/help/' + element.src" :alt="element.content" :height="height"/>
            <div class="LuisaImageDescription LuisaParagraph" v-if="element.description" v-html="element.description">
            </div>
          </div>
          <div v-html="element.content" class="LuisaCaption"/>
      </div>
</template>
<style scoped>

</style>
<style lang="scss">

</style>
<script>

export default {
    name: 'LuisaImage',
    mixins:[],
    props: ['element'],
    data: function () {
        return {        
           
        }
    },
    components: {},
    computed: {
        body () {
            if (this.element) {
                return this.element.content
            }
            return ''
        },
        height () {
            return '400px'
        }
    },
    methods: {
    },
    watch: {
    },
    async mounted () {

    }
}
</script>