
<template>
  <div class="LuisaWhy LuisaApp LuisaCommon LuisaBlog">
    <p class="LuisaParagraph">
      A constant pain point during the development of an application is the
      hand-off between the design and the development team. Often this process
      is not frictionless. The design team creates a set of graphics and
      prototypes, which serve as the source of truth and specification for the
      development team. The development team implements the visual design in the
      desired technology, which includes the translation of the design into
      front-end code and business logic, as well as the wiring between the two.
      As the development evolves, the implementation becomes a second source of
      truth, and keeping the design and the code base in sync can be a painful
      exercise.
    </p>

    <p class="LuisaParagraph">
      Handoff tools, such as Zeppelin, ease this pain by generating code
      snippets from the visual design and allowing a copy-paste workflow to
      ensure that the design stays the single source of truth. This approach can
      be further accelerated by using code generation tools such as Supernova.
      These tools take the visual design and generate front-end code. The
      developers take-over the code, improve it, include the business logic, and
      add the wiring to the generated front-end. Code generation and hand-offs
      works perfectly when the design is fixed.
    </p>

    <div class="LuisaImage LuisaImageXL">
      <div class="LuisaImageWrapper">
        <img src="/help/LowCodeWorkFlow.png" />
      </div>
      <div class="LuisaCaption">
        Code generation does not work.
      </div>
    </div>

    <p class="LuisaParagraph">
      However, in agile projects the situation is often different. The design
      changes frequently, in particular in early iterations. New user input and
      insights are gathered, and the design is improved to reflect the new
      learnings. Code generation fails at this point. It is not possible to
      simply regenerate the code base, because it would overwrite the manual
      changes that have been done by the development team. The solution of these
      tools is to generate a new code base, and leave the tedious task of
      merging up the developers.
    </p>

    <h2>Low Code as a solution</h2>

    <p class="LuisaParagraph">
      Low-Code is a term which describes a family of technologies, which aim to
      minimize the amount of manually written code. The intuition behind this
      approach is that certain aspects of an application can be visually
      modelled by domain experts. These models can be interpreted at runtime by
      special software components. The visual model serves as the single source
      of truth in the application. Luisa adopts the Low-Code idea for the
      development of user interfaces.
    </p>

    <p class="LuisaParagraph">
      The tricky decision when it comes to Low-Code is to find the right balance
      between modelling and coding. For user interfaces, the front end code is a
      great opportunity for Low-Code, as the design is always created in a
      visual design tool. Business Logic is different, however. Certain aspects
      like workflows can be easily modeled with tools like BPMN, but complex
      decision logic or connections to backend systems are often easier
      expressed in code. The main question is where to draw the line.
    </p>

    <p class="LuisaParagraph">
      Some tools try to apply the Low-Code paradigm for the entire user
      interface and create App Builders, that allow for instance the visual
      configuration of data operations or REST requests. In practise, this
      methodology suffers from several major drawbacks. First, it usually
      requires a lot of interactions with a manyfold of, menus, text- and
      dropdown boxes and so on. This makes the creation of entire applications
      cumbersome. Simple operations that can be expressed in one line of code,
      take much longer and make the developers less productive. Second, this
      approach is prone to errors. While code is checked by the compiler (and
      other utils) automatically for errors, visual tools lack the sophisticated
      and mature validation capabilities that developers are used to. For
      example, simple typos can lead to problems and debugging is often more
      difficult when compared to a full-fledged development environment such as
      Visual Studio Code. Last, the closed nature of these App Builders, makes
      it impossible to utilize the wide ecosystem of external libraries such as
      NPM.
    </p>

    <h2>The Luisa approach</h2>
    <p class="LuisaParagraph">
      We have therefore decided to <b>focus the Luisa project solely on the
      front-end code (HTML and CSS)</b> of a user interface, and leave the implementation of the
      business logic in code.
    </p>

    <p class="LuisaParagraph">
      The core of the solution is the Luisa component which reads the visual
      design and creates the front-end automatically on the fly. As a result,
      changes in the design are immediately updated in the application. The
      wiring between the front-end component and the business logic is achieved
      through method and data binding, which is defined in the <a href="https://www.figma.com/community/plugin/858477504263032980/Figma-Low-Code">Figma plugin</a>. For
      example, when a user changes the value of a text field and clicks a
      button, the data variable that is bound to the text field is updated and
      afterwards the method that is bound to the button is executed. The
      implementation of the methods and data happens in plain JavaScript and
      leaves the developer with the strong tooling support that they are used
      to. To make this approach useful for real application development, the
      Low-Code component allows full UI customizations through extension points
      and custom child components. We believe this approach offers the best
      balance between visual modelling and coding. The visual design of the user
      interface is created in Figma and the business logic is implemented in
      VUE.js with the development environment of choice. As a result, the
      development process is simplified as there is no hand-off and the amount
      of front-end code is drastically reduced.
    </p>

    <div class="LuisaImage">
      <div class="LuisaImageWrapper">
        <img src="/help/HowItWorks.png" />
      </div>
      <div class="LuisaCaption">
        Luisa renders your Figma design on the fly
      </div>
    </div>

    <p class="LuisaParagraph">
      Working with Luisa is dead simple. Once an initial design (even
      wireframes) is ready, the developers use the <a href="https://www.figma.com/community/plugin/858477504263032980/Figma-Low-Code">Figma-Low-Code plugin</a> to add
      metadata to the design. This metadata contains the wiring between the
      design and the application data and business logic. In addition the plugin
      allows specifying which UI elements to use, e.g. text input fields to
      enable the user to enter data. Afterwards, the Low-Code component must be
      imported into the VUE application and configured with a link to the Figma
      design. The design is automatically rendered without a single line of UI
      code. Also, prototyping links work out of the box. The developers can
      focus on the implementation of the data objects and business methods.
      Through the wiring defined in the plugin, data is shown at the right
      places and the business logic is invoked on user interactions. Changes in
      the design are automatically updated, and as there is no front-end code,
      there is no risk of code breaks and similar complications. How does Luisa
      work? The Luisa works currently with VUE.js, and is just another NPN
      dependency. The component is placed at the root of the application. It
      loads the Figma design from a file (or during development from the Figma
      API) and creates the required DOM elements and CSS styles on the fly. The
      data exchange between the LowCode component and the application is based
      on a ViewModel and data binding.
    </p>

    <div class="LuisaImage LuisaImageXL">
      <div class="LuisaImageWrapper">
        <img src="/help/Plugin_Type.png" />
        <div class="LuisaImageDescription LuisaParagraph">
          The Figma-Low-Code plugin allows to set meta data. You can download it <a href="https://www.figma.com/community/plugin/858477504263032980/Figma-Low-Code">here</a>
        </div>
      </div>
      
    </div>

    <p class="LuisaParagraph">
      In the plugin, the developers define the path of the data variable that
      should be bound to a certain UI element. The ViewModel holds the UI
      related data, and allows a clean separation between UI and business logic.
      When a screen is loaded, the LowCode component will populate the element
      with the right data from the ViewModel and user changes are automatically
      written back to the view model The wiring between UI elements and the
      business logic is also defined in the plugin. The developers specify the
      name of the method, which should be called for a certain event, for
      instance when a user clicks on a button, or data was changed. During
      runtime, the LowCode component checks if a method with the given name
      exists and executes it. Extensibility was a key concern during the design
      of the Luisa project. The generated CSS code follows a simple pattern
      (.screen-name .element-name) and can be overwritten or extended to
      introduce for instance hover or focus styles. When it is not possible to
      model the desired behavior of a screen section in Figma, one can define
      custom VUE components in the Figma plugin. During runtime, these
      components will be loaded and placed into the design. The developer has
      full freedom and fine-grained control over the behavior of these
      components.
    </p>

    <h2>How does it work</h2>
    <p class="LuisaParagraph">
      Luisa works currently with VUE.js, and is just another NPN dependency. The
      component is placed at the root of the application. It loads the Figma
      design from a file (or during development from the Figma API) and creates
      the required DOM elements and CSS styles on the fly.
    </p>

    <p class="LuisaParagraph">
      Figma Low Code renders the design on the fly and is bound to the business
      logic. The data exchange between the LowCode component and the application
      is based on a ViewModel and data binding. In the plugin, the developers
      define the path of the data variable that should be bound to a certain UI
      element. The ViewModel holds the UI related data, and allows a clean
      separation between UI and business logic. When a screen is loaded, the
      LowCode component will populate the element with the right data from the
      ViewModel and user changes are automatically written back to the view
      model.
    </p>

    <div class="LuisaImage">
      <div class="LuisaImageWrapper">
        <img src="/help/LuisaPrinciple.png" />
      </div>
      <div class="LuisaCaption">
        You do not need to write front-end code.
      </div>
    </div>

    <p class="LuisaParagraph">
      The data exchange between the LowCode component and the application is
      based on a ViewModel and data binding. In the plugin, the developers
      define the path of the data variable that should be bound to a certain UI
      element. The ViewModel holds the UI related data, and allows a clean
      separation between UI and business logic. When a screen is loaded, the
      LowCode component will populate the element with the right data from the
      ViewModel and user changes are automatically written back to the view
      model.
    </p>

    <p class="LuisaParagraph">
      The wiring between UI elements and the business logic is also defined in
      the plugin. The developers specify the name of the method, which should be
      called for a certain event, for instance when a user clicks on a button,
      or data was changed. During runtime, the LowCode component checks if a
      method with the given name exists and executes it.
    </p>

    <p class="LuisaParagraph">
      Extensibility was a key concern during the design of the Luisa project.
      The generated CSS code follows a simple pattern (.screen-name
      .element-name) and can be overwritten or extended to introduce for
      instance hover or focus styles. When it is not possible to model the
      desired behavior of a screen section in Figma, one can define custom VUE
      components in the Figma plugin. During runtime, these components will be
      loaded and placed into the design. The developer has full freedom and
      fine-grained control over the behavior of these components.
    </p>

    <h2>Open Source</h2>
    <p class="LuisaParagraph">
      The Figma-Low-Code project is open-source and free to use. The project
      enables a new kind of collaboration between developers and designers and
      reduces the friction between both roles. The visual design maintains the
      single source of truth, and allows the front-end developers to focus on
      business logic and backend connectivity. Design changes are automatically
      updated in the UI, without jeopardizing the existing code base. You can
      find more information at our <a href="https://github.com/KlausSchaefers/vue-low-code"> GitHub</a> page
    </p>
  </div>
</template>
<style scoped>
</style>
<style lang="scss">
</style>
<script>
export default {
  name: "LuisaTryOut",
  mixins: [],
  props: [],
  data: function () {
    return {};
  },
  components: {},
  provide() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
  async mounted() {},
};
</script>