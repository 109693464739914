<template>
  <div class="ToDoExample">  
    <Luisa :design="figmaJSON" v-model="viewModel" :config="config"/>
  </div>
</template>

<style lang="scss">
  div.ToDoExample .Home .ButtonNew {
    position: absolute;
    bottom: 24px;
    right:24px;
    top:auto
  }
</style>

<script>
import TodoService from './ToDoService'
import app from './example.json'
export default {
  name: 'Home',
  data: function () {
    return {
      figmaJSON: app,    
      viewModel: {
        searchFilter: '',
        todos: [],
        newTodo: {
          name: "",
          details: ""
        },
        selectedTodo: {
          name: "",
          details: ""
        },
      },
      config: {
        scrollToTopAfterNavigation: false,
        imageFolder: '/example',
        css: {
          selectorPrefix: '.ToDoExample'
        },
        router: {
          disabled: true,
          key: "screenName2",
          prefix: "try-out",
        },
      }  
    }
  },
  components: {
  },
  methods: {
    onNew () {
      return 'NewTodo'
    },
    onHomeLoad () {
      console.debug('Home.onHomeLoad()')
      this.viewModel.todos = TodoService.findAll()
    },
    createNewTodo (e) {
      console.debug('Home.createNewTodo()', e.viewModel.newTodo.name, e.viewModel.newTodo.details)
      TodoService.create(e.viewModel.newTodo.name, e.viewModel.newTodo.details)
      e.viewModel.newTodo.name = ''
      e.viewModel.newTodo.details = ''
      return 'Home'
    },
    updateTodo (e) {
      console.debug('Home.updateTodo()', e.viewModel.selectedTodo.name, e.viewModel.selectedTodo.id)
      TodoService.update(e.viewModel.selectedTodo.id, e.viewModel.selectedTodo.name, e.viewModel.selectedTodo.details)
      return 'Home'
    },
    deleteTodo (e) {
      console.debug('Home.deleteTodo()', e.viewModel.selectedTodo.name, e.viewModel.selectedTodo.id)
      TodoService.delete(e.viewModel.selectedTodo.id)
      return 'Home'
    },
    filterTodo (e) {
      console.debug('Home.filterTodo()', e.viewModel.searchFilter)
      this.viewModel.todos = TodoService.findByFilter(e.viewModel.searchFilter)
    }
  }
}
</script>
