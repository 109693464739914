
<template>
    <div class="LuisaTryOut LuisaApp LuisaCommon">
      

        <div class="LuisaTryOutExample">
            <div class="LuisaMobileWrapper">
                <ToDoExample />
            </div>
           
              <CodePreview :element="code" size="s" class=""/>
        
        </div>

      

    </div>
</template>
<style scoped>

</style>
<style lang="scss">
@import '../../scss/example.scss';

</style>
<script>

import CodePreview from '../help/CodePreview.vue'
import ToDoExample from './ToDoExample.vue'
import * as Code from './Code.js'

export default {
    name: 'LuisaTryOut',
    mixins:[],
    props: [],
    data: function () {
        return {
            isWindows: false,
            code : Code.getMainCode()
        }
    },
    components: {
        CodePreview, ToDoExample
    },
    provide () {
        return {      
            'vueVersion': 2
        }
    },
    computed: {   
       
    },
    methods: {
        
    },
    watch: {
       
    },
    async mounted () { 
    }
}
</script>