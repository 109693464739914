
<template>
    <div class="LuisaHelp LuisaApp LuisaCommon">
            <div class="LuisaHelpContent">
                <div class="LuisaHelpSideBar" v-if="hasSideBar">
                    <div v-if="hasSearch">
                        <input type="search" class=" MatcCreateSearch MatcIgnoreOnKeyPress form-control" placeholder="Search" v-model="search"/>
                    </div>
                    <span v-if="loading" class="MatchHint">
                        Loading...
                    </span>
                    <div class="LuisaHelpTopicsCntr">
    
                        <template v-for="topic in topics" :key="topic.id">
                            <router-link @click="setTopic(topic.id)" :to="'/help/' + topic.id + '.html'" :class="[{'selected': topic.id === selected && !selectedElement}, topic.css]">
                                {{topic.name}}
                            </router-link>
                            <template v-if="topic.id === selected || expandAll">
                                <a  v-for="(headline, i) in getHeadlines(topic)" :key="i" :class="['LuisaHelpSubTopic', {'selected': headline.id === selectedElement}]" @click.stop="setElement(headline.id)" >
                                    {{headline.content}}
                                </a>
                            </template>
                        </template>
                            
                </div>
            </div>
            <span v-if="loading" class="MatchHint">
                Loading...
            </span>
            <div v-else class="LuisaHelpContentCntr" @click="onClick">     

                <h1>{{current.name}}</h1>                  

                 
                <component 
                    class="HelpElement" 
                    :is="element.type" 
                    :element="element" 
                    @vueVersion="setVueVersion"  
                    v-for="(element,i) in current.elements" 
                    :key="i"       
                    :ref="el => { if (el) paragraphDivs[i] = el }" />

                <div class="luisa-nav-btn-bar" v-if="hasNext">
                    <a :href="nextHref" class="luisa-btn-primary LuisaHelpLink">Next</a> 
                </div>
          

            </div>
  
        </div>

    </div>
</template>
<style scoped>

</style>
<style lang="scss">
@import '../../scss/help.scss';
@import '../../scss/form.scss';
</style>
<script>

import HelpService from '../../services/HelpService'
import Paragraph from './Paragraph.vue'
import CodePreview from './CodePreview.vue'
import Image from './Image.vue'
import Headline from './Headline.vue'
import Video from './Video.vue'
import Playground from './Playground.vue'
import {computed} from 'vue'

export default {
    name: 'Help',
    mixins:[],
    props: ['help', 'standalone'],
    data: function () {
        return {
            texts: [],
            selected: "luisa_framework",
            selectedElement: "",
            search: "",
            hasSideBar: true,
            hasSearch: false,
            hasNotifications: false,
            contactName: '',
            contactEmail: '',
            contactMessage:'',
            contactError: '',
            concatSucess: '',
            videoScaleFactor: 1.2,
            expandAll: false,
            versionOfVue: 3,
            paragraphDivs: []
        }
    },
    components: {
        'Paragraph': Paragraph,
        'CodePreview': CodePreview,
        'Image': Image,
        'Headline': Headline,
        'Playground': Playground,
        'Video': Video
    },
    provide () {
        return {      
            'vueVersion': computed(() => this.versionOfVue)
        }
    },
    computed: {
        loading () {
            return this.texts.length === 0
        },
        current () {
            let text = this.texts.find(t => t.id === this.selected)
            if (!text) {
                text = this.texts[0]
            }
            return text
        },
        topics () {
            let query = this.search.toLowerCase()
            if (query.length > 2) {
                return this.texts.filter(t => {
                    if (t._all && t._all.indexOf(query) >= 0) {
                        return true
                    }
                })
            }
           return this.texts
        },
        hasNext () {
            let index = this.texts.findIndex(t => t.id === this.selected)
            console.debug(index, this.texts.length)
            return index < this.texts.length - 1
        },
        nextHref () {
            let index = this.texts.findIndex(t => t.id === this.selected)
            if (index + 1 < this.texts.length - 1) {
                let next = this.texts[index + 1]
                return `/help/${next.id}.html`
            }
            return ""
        }
    },
    methods: {
        getHeadlines (topic) {
            return topic.elements.filter(e => e.type === 'Headline')
        },
        setVueVersion (v) {
            this.versionOfVue = v
        },
        setTopic (t) {
            this.selected = t
            this.selectedElement = ""
        },
        setElement (paragraph) {
            this.selectedElement = paragraph
            this.$nextTick( () => {
                this.focus(paragraph)
            })
        },
        focus (id) {                
            let p = this.paragraphDivs.find(p => {
                return p.element && p.element.id === id;
            })
            if (p) {
                p.$el.scrollIntoView()
            }            
        },
        setTopicByRoute () {
            if (this.$route.params.section) {
                this.setTopic(this.$route.params.section)
            }           
        },
        onClick (e) {
            let target = e.target         
            if (target && target.className.indexOf('LuisaHelpLink') >= 0) {
                let url = new URL(target.href)
                e.preventDefault()
                this.$router.push(url.pathname)
                window.scrollTo(0, 0)
            }         
        }
    },
    watch: {
        $route () {
            this.setTopicByRoute()
        }
    },
    async mounted () {
      
        this.videoScaleFactor = 0.9
        
        let texts = await HelpService.getAll()        
        this.texts = texts
        if (this.selectedElement) {
            this.$nextTick( () => {
                this.focus(this.selectedElement)
            })
        }
  
        this.setTopicByRoute()
        
    }
}
</script>