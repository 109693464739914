import Logger from '../util/Logger'

export default class LuisaService {

    sendContact(email, message) {
        Logger.log(0, 'LuisaService.sendContact', 'enter')
        return this._post(`https://quant-ux.com/rest/contact`, {
            name: 'Contact from Luisa.cloud',
            email: email,
            message: message
        })
    }

    _post(url, data, headers = {}) {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'post',
                body: JSON.stringify(data),
                headers: headers
            }).then((res) => {
                if (res.status === 200) {
                    res.json().then(j => {
                        Logger.log(6, 'LuisaService._post', 'exit ')
                        resolve(j)
                    })
                } else {
                  reject(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }
}