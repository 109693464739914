
<template>
      <div class="LuisaCodePreview ">      
          <div class="LuisaTabBar" v-if="element.tabs && element.tabs.length > 1">
              <a v-for="tab in element.tabs" :key="tab.vue" @click="selectTab(tab)" :class="['LuisaTab', {'LuisaTabSelected' : tab.vue === vueVersion}]">
                  {{tab.label}}
              </a>
          </div>
           <div :class="[
                    'LuisaTabContr LuisaCodeWrapper', 
                    {'LuisaCodeWrapperS' :size === 's'}, 
                    {'LuisaCodeWrapperHasTabs': element.tabs && element.tabs.length > 0}
            ]" >
                <pre :class="language"><code ref="code">{{strippedContent}}</code></pre>
          </div>
        <div v-if="element.caption" class="LuisaCaption">
            {{element.caption}}
        </div>
          
      </div>
</template>
<style scoped>

</style>
<style lang="scss">
@import '../../scss/code-preview.scss';
@import '../../scss/tab.scss';
</style>
<script>


// Highlight.js languages (Only required languages)
import hljs from 'highlight.js';
import javascript from 'highlight.js/lib/languages/javascript'
import xml from 'highlight.js/lib/languages/xml'
import css from 'highlight.js/lib/languages/css'
import bash from 'highlight.js/lib/languages/bash';
import 'highlight.js/styles/github-dark.css';

export default {
    name: 'CodePreview',
    mixins:[],
    props: ['element', 'size'],
    data: function () {
        return {        
         
        }
    },
    inject: ['vueVersion'],
    emits: ['vueVersion'],
    components: {},
    computed: {
        language () {
            if (this.element && this.element.language) {
                return this.element.language
            }
            return 'vue'
        },
        body () {
            if (this.element) {
                return this.element.content
            }
            return ''
        },
        strippedContent () {           
            this.$nextTick( () => this.hljs.highlightElement(this.$refs.code))
            return this.content.trim()
        },
        content () {

            if (this.element.tabs) {
                let tab = this.element.tabs.find(t => t.vue === this.vueVersion)
                if (!tab) {
                    tab = this.element.tabs[0]
                }
                if (tab) {
                    return tab.content
                }
            }
            return this.element.content
        }
    },
    methods: {
        selectTab(tab) {
            this.$emit('vueVersion', tab.vue)
        }
    },
    watch: {

    },
    async mounted () {     
        hljs.registerLanguage('javascript', javascript)
        hljs.registerLanguage('html', xml)
        hljs.registerLanguage('css', css)
        hljs.registerLanguage('vue', xml)
        hljs.registerLanguage('bash', bash)
        this.hljs = hljs
    }
}
</script>