import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import qux from './qux'
import Help from './views/help/Help.vue'
import LuisaExample from './views/example/Example.vue'
import Tryout from './views/tryout/Tryout.vue'
import Why from './views/why/Why.vue'

const app = createApp(App)
app.use(router).mount('#app')
app.use(qux)
app.component('LuisaHelp', Help)
app.component('LuisaExample', LuisaExample)
app.component('LuisaTryout', Tryout)
app.component('LuisaWhy', Why)
app.config.unwrapInjectedRef = true // can ber removed sometime in the future