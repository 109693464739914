<template>
  <div class="luisa-landing luisa-low-code-wrapper">
    <Luisa :design="figmaJSON" :config="config" v-model="viewModel" :pages="['Desktop', 'Mobile']"/>
  </div>
</template>
<style lang="scss">

</style>
<script>

import LuisaService from '../services/LuisaService'
import Logger from '../util/Logger'
import app from './app.json'

export default {
  name: 'Home',
  data: function () {
    return {
      design: '',
      figmaJSON: app,
      figmaConfig: {
        figmaFile: "6UDfn34lxglCFrA9bE4AUr",
        figmaAccessKey: "48109-41e7ee0d-48eb-4f88-af6e-9f1bc4072dda",
      },
      responsive: null,
      defaultApp: 'a2aa10aAEeywWCOpCD2hhj48qMv2OopC7V0HomvW3ttzabjOyZXWoWaNZ3lu',
      debug: false,
      isQUX: true,
      actions: null,
      viewModel: {
          contact: {
            status: '',
            email: '',
            message: ''
          }          
      },
      config: {
        loadFonts: false,
        loadFontsWarning: false,
        responsive: [
          { page: "Desktop", types: ["desktop", ""] },
          { page: "Mobile", types: ["mobile"] },
        ],
        css: {
          attachLabels: false,
          huggedCanResize: true
        },
        figma: {
          downloadVectors: true,
        },
        router: {
        }
      }
    }
  },
  components: {
  },
  methods: {
    sendContact() {
      this.viewModel.contact.status = 'Message was send'
      this.luisaService.sendContact(this.viewModel.contact.email, this.viewModel.contact.message)      
    }
  },
  async mounted () {
    Logger.log(-1, 'Home.mounted()', location.hostname)
    this.luisaService = new LuisaService()
  }
}
</script>
