
<template>
    <div class="LuisaVideo LuisaApp LuisaCommon">
            
        <div class="LuisaHelpContentVideo" >
          
                <iframe
                    v-if="element.video"
                    :width="560 * videoScaleFactor"
                    :height="315 * videoScaleFactor"
                    title="YouTube video player" 
                    :src="element.video"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
        </div> 
        <div v-html="element.content" class="LuisaCaption"/>

    </div>
</template>

<script>

export default {
    name: 'LuisaHelpVideo',
    mixins:[],
    props: ['element'],
    data: function () {
        return {
            videoScaleFactor: 1.2
        }
    },
    components: {},
    computed: {
    },
    mounted () {
        let w = window.outerWidth
        if (w < 560  * 1.2) {
            this.videoScaleFactor = w / (560  * 1.2)
        }
    }
}
</script>