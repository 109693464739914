import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Embedded from '../views/embedded/Embedded.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:screenName.html',
    name: 'Home2',
    component: Home
  },
  {
    path: '/:screenName/:section.html',
    name: 'Home3',
    component: Home
  },
  {
    path: '/test/qux.html',
    nane: 'Qux Test',
    component: () => import(/* webpackChunkName: "test" */ '../test/QuxTest.vue')
  },
  {
    path: '/test/qux/:screenName.html',
    nane: 'Qux Test',
    component: () => import(/* webpackChunkName: "test" */ '../test/QuxTest.vue')
  },
  {
    path: '/test/figma.html',
    nane: 'Figma Test',
    component: () => import(/* webpackChunkName: "test" */ '../test/FigmaTest.vue')
  },
  {
    path: '/test/figma/:screenName.html',
    nane: 'Qux Test',
    component: () => import(/* webpackChunkName: "test" */ '../test/FigmaTest.vue')
  },
  {
    path: '/test/IFrame.html',
    nane: 'Embedded',
    component:  () => import(/* webpackChunkName: "test" */ '../test/IFrameTest.vue')
  },
  {
    path: '/embedded/:id.html',
    nane: 'Embedded',
    meta: {
      exlude: '/embedded',
    },
    component: Embedded
  },
  {
    path: '/embedded/:id/:screenName.html',
    nane: 'Embedded',
    component: Embedded
  },
  {
    path: '/cinematic/home.html',
    nane: 'Cinematic',
    component: () => import(/* webpackChunkName: "cinematic" */ '../views/cinematic/Cinematic.vue')
  },
]

const isHashRouter = true
const router = createRouter({
  history: isHashRouter ? createWebHashHistory () : createWebHistory(),
  routes
})

export default router
