
<template>
      <h2 v-html="body" class=""></h2>
</template>
<style scoped>

</style>
<style lang="scss">

</style>
<script>

export default {
    name: 'LuisaHeadline',
    mixins:[],
    props: ['element'],
    data: function () {
        return {        
           
        }
    },
    components: {},
    computed: {
        body () {
            if (this.element) {
                return this.element.content
            }
            return ''
        }
    }
}
</script>