export function getMainCode() {
    return  {                
        "type" : "CodePreview",
        "language": "vue",
        "content": `
<template>
    <div class="todo">
        <Luisa 
            :design="figmaJSON" 
            v-model="viewModel
        "/>
    </div>
</template>

<script>
import TodoService from './ToDoService'
import app from './app.json'

export default {
    name: 'ToDo',
    data: function () {
        return {
            figmaJSON: app,             
            viewModel: {
                searchFilter: '',
                todos: [],
                newTodo: {
                    name: "",
                    details: ""
                },
                selectedTodo: {
                    name: "",
                    details: ""
                }
            }
        },       
    },
    methods: {
        onHomeLoad () {             
            this.viewModel.todos = 
                TodoService.findAll()
        },
        createNewTodo (e) {         
            TodoService.create(
                e.viewModel.newTodo.name, 
                e.viewModel.newTodo.details
            )
            // reset values
            e.viewModel.newTodo.name = ''
            e.viewModel.newTodo.details = ''
            // set next screen to show
            return 'Home'
        },
        updateTodo (e) {  
            // pass viewModel to service              
            TodoService.update(
                e.viewModel.selectedTodo.id, 
                e.viewModel.selectedTodo.name, 
                e.viewModel.selectedTodo.details
            )
            // set next screen to show
            return 'Home'
        },
        deleteTodo (e) {
            TodoService.delete(
                e.viewModel.selectedTodo.id
            )
            return 'Home'
        },
        filterTodo (e) {
            this.viewModel.todos = TodoService
                .findByFilter(
                    e.viewModel.searchFilter
                )
        }
    }
}
</script>
`
}
}