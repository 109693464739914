
<template>
    <div>
      <div class="LuisaPlayground">
    
          <div v-if="element" :class="['LuisaPlaygroundCntr', {'LuisaPlaygroundAnimated': element.amimation === 'grow'}]">


              <div v-if="element.mode === 'grid'" class="LuisaPlaygroundExample" style="display:flex; justify-content:space-around; align-items:center">
                  <div class="LuisaPlaygroundElementFlex" style="width:150px; height:80%; position:relative ">
                      <div class="LuisaPlaygroundElement" style="width:110px; height:20px; position:absolute; top:20px; left:20px">
                            A
                      </div>
                      <div class="LuisaPlaygroundElement" style="width:110px; height:20px; position:absolute; top:60px; left:20px">
                          B
                      </div>
                      <div class="LuisaPlaygroundElement" style="width:110px; height:20px; position:absolute; top:100px; left:20px">
                          C
                      </div>
                  </div>
                  <div class="LuisaPlaygroundElementGrid" style="width:150px; height:80%; position:relative">
                      <div class="LuisaPlaygroundElement" style="width:70px; height:20px; position:absolute; top:20px; left:20px">
                          A
                      </div>
                      <div class="LuisaPlaygroundElement" style="width:70px; height:20px; position:absolute; top:60px; left:20px">
                          B
                      </div>
                      <div class="LuisaPlaygroundElement" style="width:70px; height:20px; position:absolute; top:100px; left:20px">
                          C
                      </div>
                      <div class="LuisaPlaygroundElement" style="width:20px; height:100px; position:absolute; top:20px; left:110px">
                          D
                      </div>
                  </div>
              </div>

              <div v-if="element.mode === 'absolute'" class="LuisaPlaygroundExample" style="position: relative;">
                  <div class="LuisaPlaygroundElementAbsolute" style="width: 200px; height:30px; position: absolute; top:20px; left: 20px"/>
                  <div class="LuisaPlaygroundElementAbsolute" style="width: 200px; height:100px; position: absolute; top:70px; left: 20px"/>
              </div>

             <div v-if="element.mode === 'relative'" class="LuisaPlaygroundExample" style="position: relative;">
                  <div class="LuisaPlaygroundElementRelative" style="width: 40%; height:30px; position: absolute; top:20px; left: 20%"/>
                  <div class="LuisaPlaygroundElementRelative" style="width: 40%; height:100px; position: absolute; top:70px; left: 20%"/>
              </div>

              <div v-if="element.mode === 'centered'" class="LuisaPlaygroundExample" style="display:flex; justify-content:center;">
                  <div class="LuisaPlaygroundElementCentered" style="width:250px; height:100%"/>
              </div>

              <div v-if="element.mode === 'minmax'" class="LuisaPlaygroundExample" style="display:flex; justify-content:center;">
                  <div class="LuisaPlaygroundElementCentered" style="width:70%; min-width:250px; max-width:300px; height:100%"/>
              </div>

              <div v-if="element.mode === 'pinned'" class="LuisaPlaygroundExample" style="display:flex; position:absolute">
                  <div class="LuisaPlaygroundElementPinned" style=" height:30px; position:absolute; top:20px; left:20px; right:40px"/>
                  <div class="LuisaPlaygroundElementPinned" style=" height:30px; position:absolute; top:70px; left:20px; right:80px"/>
                  <div class="LuisaPlaygroundElementPinned" style=" height:30px; position:absolute; top:120px; left:80px; right:20px"/>
              </div>

               <div v-if="element.mode === 'auto'" class="LuisaPlaygroundExample" style="display:flex; position:absolute">
                  <div class="LuisaPlaygroundElementAuto" style=" height:90px; position:absolute; top:20px; left:40px; right:40px">
                    <div class="LuisaPlaygroundElementFixed" style=" height:50px; position:absolute; top:20px; left:20px; width:50px"/>
                    <div class="LuisaPlaygroundElementFill" style=" height:50px; position:absolute; top:20px; left:90px; right:20px"/>
                  </div>

                <div class="LuisaPlaygroundElementAuto" style=" height:90px; position:absolute; top:150px; left:40px; right:40px">
                    <div class="LuisaPlaygroundElementFill" style=" height:50px; position:absolute; top:20px; left:20px; right:70px"/>
                    <div class="LuisaPlaygroundElementFixed" style=" height:50px; position:absolute; top:20px; right:20px; width:30px"/>               
                </div>               
              </div>

              <div v-if="element.mode === 'wrapped'" class="LuisaPlaygroundExample" style="">

                  <div class="LuisaPlaygroundElementGrid" style="margin-top: 30px; margin-left:30px; margin-right:30px; 
                    display:flex; justify-content:space-between; flex-wrap: wrap; 
                    align-content: flex-start; padding:10px">
              
                    <div class="LuisaPlaygroundElementAbsolute" style=" height:50px; width:70px; margin:10px;"/>
    
                    <div class="LuisaPlaygroundElementAbsolute" style=" height:50px; width:70px; margin:10px;"/>

                    <div class="LuisaPlaygroundElementAbsolute" style=" height:50px; width:70px; margin:10px;"/>

                    <div class="LuisaPlaygroundElementAbsolute" style=" height:50px; width:70px; margin:10px;"/>

                    <div class="LuisaPlaygroundElementAbsolute" style=" height:50px; width:70px;  margin:10px;"/>

                    <div class="" style=" height:50px; width:70px;  margin:10px;"/>
                  </div>
              </div>

              <div v-if="element.mode === 'fixedScroll'" class="LuisaPlaygroundExample" style="display:flex; justify-content:center; position: relative;">
                  <div class="LuisaPlaygroundElementCentered LuisaPlaygroundElementScroll" style="width:250px; height:250px; margin-top:20px"/>
                  <div class="LuisaPlaygroundElementFixedScroll" style="width: 60px; height:60px; border-radius:50px; position: absolute; bottom:40px; right: 40px"/>
              </div>

              <div v-if="element.mode === 'repeater'" class="LuisaPlaygroundExample" style="display:flex; justify-content:center; position: relative;">
                  
                  <div class="LuisaPlaygroundElementCentered LuisaPlaygroundElementRepeat" style="width:250px; height:200px; margin-top:20px; padding:20px; position: relative;">
                  <span class="LuisaPlaygroundElementDataBinding">{array}</span>
                        <div :class="'LuisaPlaygroundElementRepeatAdd Child'+i " style="width:100%; height:40px; display:flex; flex-direction:row; margin-bottom:30px" v-for="i in three" :key="i">
                            <div class="LuisaPlaygroundElement" style="width:50px; height:20px; margin:10px">
                                A
                            </div>
                            <div class="LuisaPlaygroundElement" style="width:50px; height:20px; margin:10px">
                                B
                            </div>
                            <div class="LuisaPlaygroundElement" style="width:50px; height:20px; margin:10px">
                                C
                            </div>
                        </div>

                  </div>
               </div>


              <div v-if="element.mode === 'repeater2'" class="LuisaPlaygroundExample" style="display:flex; justify-content:center; position: relative;">
                  <div class="LuisaPlaygroundElementCentered LuisaPlaygroundElementRepeat" style="position:relative; width:250px; height:200px; margin-top:20px; padding:20px">
                      <span class="LuisaPlaygroundElementDataBinding">{friends}</span>
                        <div :class="'LuisaPlaygroundElementRepeatAdd Child'+i " 
                             style="position:relative; width:100%; height:40px; display:flex; flex-direction:row; margin-bottom:30px" 
                             v-for="(f,i) in friends" :key="i">
                            
                            <div class="LuisaPlaygroundElement" style="width:70px; height:20px; margin:10px; position:relative">
                                {{f.name}}
                                <span class="LuisaPlaygroundElementDataBinding" v-if="i===0">{name}</span>
                            </div>
                            <div class="LuisaPlaygroundElement" style="width:100px; height:20px; margin:10px; position:relative">
                                {{f.lastname}}
                                <span class="LuisaPlaygroundElementDataBinding" v-if="i===0">{lastname}</span>
                            </div>
                        </div>

                  </div>
               </div>

              <div v-if="element.mode === 'repeater3'" class="LuisaPlaygroundExample" style="position: relative;">
                  
                  <div class="LuisaPlaygroundElementCentered LuisaPlaygroundElementRepeat LuisaPlaygroundElementRepeatScreen1" 
                        style="position:absolute; width:250px; height:200px;  padding:20px; top:20px; left:50px">
                        <span class="LuisaPlaygroundElementDataBinding">{input:friends; output:selected}</span>
                        <div :class="'LuisaPlaygroundElementRepeatSelect Child'+i " 
                                style="position:relative; width:100%; height:40px; display:flex; flex-direction:row; margin-bottom:30px" 
                                v-for="(f,i) in friends" :key="i">
                                
                                <div class="LuisaPlaygroundElement" style="width:70px; height:20px; margin:10px; position:relative">
                                    {{f.name}}
                                    <span class="LuisaPlaygroundElementDataBinding" v-if="i===0">{name}</span>
                                </div>
                                <div class="LuisaPlaygroundElement" style="width:100px; height:20px; margin:10px; position:relative">
                                    {{f.lastname}}
                                    <span class="LuisaPlaygroundElementDataBinding" v-if="i===0">{lastname}</span>
                                </div>
                        </div>
                        
                  </div>

                  <div class=" LuisaPlaygroundElementRepeatScreen2 LuisaPlaygroundElementDataBindingSelected" 
                       style="position:absolute; width:250px; height:200px;  padding:20px; top:20px; left:50px">
                     
                       
                            
                            <div class="LuisaPlaygroundElement" style="width:200px; height:20px; margin:10px; margin-bottom:30px; position:relative">
                                {{selected.name}}
                                <span class="LuisaPlaygroundElementDataBinding">{selected.name}</span>
                            </div>
                            <div class="LuisaPlaygroundElement" style="width:200px; height:20px; margin:10px; margin-bottom:30px; position:relative">
                                {{selected.lastname}}
                                <span class="LuisaPlaygroundElementDataBinding">{selected.lastname}</span>
                            </div>

                            <div class="LuisaPlaygroundElement" style="width:200px; height:20px; margin:10px; position:relative">
                                {{selected.age}}
                                <span class="LuisaPlaygroundElementDataBinding">{selected.age}</span>
                            </div>
                        

                  </div>
               </div>

                <div v-if="element.mode === 'contactForm'" class="LuisaPlaygroundExample" style="display:flex; justify-content:center; position: relative;">
                  <div class="LuisaPlaygroundElementForm" style="position:relative; width:250px; height:200px; margin-top:20px; padding:20px">
                      
                      <label>Your Email</label>
                      <div style="position:relative">
                        <span class="LuisaPlaygroundElementDataBinding">{contact.email}</span>
                        <div class="LuisaPlaygroundExampleFormInput" placeholder="" >
                            How can we reach you
                        </div>
                      </div>
                      <label>Your Message</label>
                      <div style="position:relative">
                          <span class="LuisaPlaygroundElementDataBinding">{contact.message}</span>
                          <div class="LuisaPlaygroundExampleFormInput LuisaPlaygroundExampleFormInputXL">
                              Share your thoughts
                          </div>
                      </div>
                      <div class="LuisaPlaygroundExampleButtonBar">
                          <div class="LuisaPlaygroundExampleButton" style="position:relative">
                           <span class="LuisaPlaygroundElementDataBinding LuisaPlaygroundElementMethodBinding" v-if="element.labels.methodbinding">{sendContact}</span>
                              Submit
                         </div>
                            <div style="position:relative">
                            <span class="LuisaPlaygroundElementDataBinding">{contact.status}</span>
                            <div class="LuisaPlaygroundExampleButtonLabel">
                                Fill out the form
                            </div>
                        </div>
                      </div>
                      

                  </div>
               </div>

              <div class="LuisaPlaygroundPlay" v-if="element.play">
                  <i class="mdi mdi-play-circle-outline"/>
              </div>

              
          </div>
      </div>
      <div class="LuisaPlaygroundLabels" v-if="element.labels">    
         
           <div class="LuisaPlaygroundLabel" v-if="element.labels.wrapped">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementGrid"/> Wrapped
          </div>
           <div class="LuisaPlaygroundLabel" v-if="element.labels.minmax">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementCentered"/> Min Max (centered)
          </div>
          <div class="LuisaPlaygroundLabel" v-if="element.labels.flex">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementFlex"/> Flex
          </div>
           <div class="LuisaPlaygroundLabel" v-if="element.labels.grid">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementGrid"/> Grid
          </div>         
          <div class="LuisaPlaygroundLabel" v-if="element.labels.absolute">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementAbsolute"/> Absolute
          </div>
          <div class="LuisaPlaygroundLabel" v-if="element.labels.centered">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementCentered "/> Centered
          </div>
          <div class="LuisaPlaygroundLabel" v-if="element.labels.pinned">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementPinned"/> Pinned
          </div>
          <div class="LuisaPlaygroundLabel" v-if="element.labels.auto">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementAuto"/> Auto (Pinned)
          </div>
        <div class="LuisaPlaygroundLabel" v-if="element.labels.relative">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementRelative"/> Relative
          </div>
          
          <div class="LuisaPlaygroundLabel" v-if="element.labels.fixed">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementFixed"/> Fixed
          </div>
               <div class="LuisaPlaygroundLabel" v-if="element.labels.fixedScroll">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementFixedScroll"/> Fixed Scroll
          </div>
          <div class="LuisaPlaygroundLabel" v-if="element.labels.fixed">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementFill"/> Fill
          </div>

          <div class="LuisaPlaygroundLabel" v-if="element.labels.repeater">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementRepeat"/> Repeater
          </div>
           <div class="LuisaPlaygroundLabel" v-if="element.labels.databinding">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementDataBinding"/> {data-binding}
          </div>
          <div class="LuisaPlaygroundLabel" v-if="element.labels.selected">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementDataBindingSelected"/> Selected
          </div>
          <div class="LuisaPlaygroundLabel" v-if="element.labels.methodbinding">
              <div class="LuisaPlaygroundIcon LuisaPlaygroundElementMethodBinding"/> {method-binding}
          </div>
      </div>
    </div>
</template>
<style scoped>

</style>
<style lang="scss">
@import '../../scss/playground.scss';
</style>
<script>

export default {
    name: 'Playground',
    mixins:[],
    props: ['element'],
    data: function () {
        return {        
           three: [0, 1, 2],
           friends: [
                {name: 'Klausen', lastname: 'Pastore'},
                {name: 'Tasha', lastname: 'Lichtenfels'},
                {name: 'Mestre', lastname: 'Bimba'},
            ],
            selected: {name: 'Klausen', lastname: 'Pastore', age:23},
        }
    },
    inject: ['vueVersion'],
    emits: ['vueVersion'],
    components: {},
    computed: {
        body () {
            if (this.element) {
                return this.element.content
            }
            return ''
        }
    },
    methods: {
    },
    watch: {
    },
    async mounted () {
    }
}
</script>